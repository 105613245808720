//
// ------------------------------------------------------------------------
// Blur
// ------------------------------------------------------------------------
//

[data-tor*="blur("],
[data-tor*="blur."] {
  filter: blur(var(--tor-blur, 0));

  @each $key, $value in $blur-values {
    --tor-blur-#{$key}: #{$value};
  }
}