//
// ------------------------------------------------------------------------
// Text color
// ------------------------------------------------------------------------
//

[data-tor*="text("] {
  @each $name, $value in $text-colors-hsla {
    --tor-text-#{$name}: #{$value};
  }
  --tor-text-transparent: transparent;
}