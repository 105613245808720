//
// ------------------------------------------------------------------------
// Fade & Opacity
// ------------------------------------------------------------------------
//

[data-tor*="opacity"],
[data-tor*="fade."] {
  opacity: var(--tor-opacity);
}
