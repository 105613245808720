//
// ------------------------------------------------------------------------
// Position
// ------------------------------------------------------------------------
//

$utilities: (
  "position": (
    property: position,
    responsive: true,
    values: absolute relative fixed,
  ),
);

@import "../../bootstrap/utilities/call-utilities-api";

.position-fixed {
  position: fixed !important;
}

[data-tor*="place."],
[data-tor*="shift."] {
  --tor-top: auto;
  --tor-right: auto;
  --tor-bottom: auto;
  --tor-left: auto;
  --tor-shiftX: 0%;
  --tor-shiftY: 0%;

  top: var(--tor-top);
  right: var(--tor-right);
  bottom: var(--tor-bottom);
  left: var(--tor-left);
}

[data-tor~="place.top"] {
  --tor-top: 0;
}
[data-tor~="place.bottom"] {
  --tor-bottom: 0;
}
[data-tor~="place.left"] {
  --tor-left: 0;
}
[data-tor~="place.right"] {
  --tor-right: 0;
}

[data-tor~="place.middle"] {
  --tor-top: 50%;
  --tor-shiftY: -50% !important;
}
[data-tor~="place.center"] {
  --tor-left: 50%;
  --tor-shiftX: -50% !important;
}
